<template>
    <div class="loginMain">
        <!-- <el-button type="primary" @click="$router.push({name:'schoolIndex'})">学校端</el-button>
        <el-button type="primary" @click="$router.push({name:'professorIndex'})">专家端</el-button>
        <el-button type="primary" @click="$router.push({name:'sendPaperIndex'})">送审端</el-button>
        <el-card class="login">
            <el-form label-position="right" label-width="80px" :model="login">
                <el-form-item label="用户名">
                    <el-input v-model="login.account"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="login.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleLogin">登录</el-button>
                </el-form-item>
            </el-form>
        </el-card> -->

        <div>
            <div class="bg1"></div>
            <div class="gyl">
                学位论文送审平台-院校端
                <!-- <div class="gy2">打造国内一流的论文送审平台 </div> -->
            </div>
            <div class="bg">
                <div class="wel">
                    <!-- <span style="font-size:20px;">账号登录</span> -->
                    <el-radio-group v-model="loginType" @change="changeHandle">
                        <el-radio :label="0" style="margin-right:20px">账号登录</el-radio>
                        <!-- <el-radio :label="1">验证码登录</el-radio> -->
                    </el-radio-group>
                    <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="账号登录" name="first">
                            dasfdsf
                        </el-tab-pane>
                        <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
                    </el-tabs> -->
                </div>
                <transition name="el-zoom-in-center">
                    <div v-if='loginType == 0'>
                        <div class="user">
                            <div id="yonghu" style="">用户名</div>
                            <input type="text" name="dxaccount" v-model="login.account" @keydown="keydown" />
                        </div>
                        <div class="password">
                            <div id="yonghu">密&nbsp;&nbsp;&nbsp;码</div>
                            <input type="password" name="dxpassword" v-model="login.password" @keydown="keydown" />
                        </div>
                        <div class="rem">
                            <input type="checkbox" v-model="login.rem" @keydown="keydown" />
                            <div id="reb">
                                记住密码
                            </div>
                        </div>
                        <div class="fg">
                            <div style="font-size: 11px;margin-top: 11px;">
                                <a style="font-size: 11px;" href="#">忘记密码？</a>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="el-zoom-in-center">
                    <div v-if='loginType == 1'>
                        <div class="user">
                            <div id="yonghu" style="">手机号</div>
                            <input type="text" name="dxmobile" v-model="loginYzm.mobile" @keydown="keydown" />
                            <!-- <div id="qwe" style="">手机号</div>
                        <el-input v-model="login.password" label="qwe" placeholder=""></el-input> -->
                        </div>
                        <div class="yzm">
                            <div id="yonghu">验证码</div>
                            <input type='text' name="dxcode" v-model="loginYzm.verCode" @keydown="keydown" />
                            <el-button type="primary" plain class="yzmBtn" :disabled='!showBtn' size="mini"
                                @click="getCode">
                                {{showBtn?'点击获取验证码':second+'秒后重新获取'}}
                            </el-button>
                        </div>
                    </div>
                </transition>
                <input ref='btn' class="btn" @click="handleLogin" type="button" name="登录" value="登录" />
            </div>
            <div></div>
            <div class="bah">
                <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022003435号-1</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        setToken
    } from '@/tools/auth'
    import{
      computedAsyncRoutes
    } from '@/store/modules/user.js'
    import{
      asyncRoutes
    } from "@/router";
    import {cloneDeep} from 'lodash'
    export default {
        name: 'login',
        data() {
            return {
                loginType: 0,
                institutionName: '',
                login: {
                    account: '',
                    password: '',
                    rem: false
                },
                loginYzm: {
                    mobile: '',
                    verCode: ''
                },
                showBtn: true,
                second: '',
                timer: null
            }
        },
        created() {
            // let token = this.$route.query['token'];
            // if (typeof (token) != 'undefined') {
            //     this.tokenLogin(token);
            // }
            this.login.account = this.getCookie('dxaccount');
            this.login.password = this.getCookie('dxpassword');
            this.login.rem = this.getCookie('dxaccount') != '';
        },
        methods: {
            //enter
            keydown(e) {
                if (e.keyCode == 13)
                    this.$refs.btn.click();
            },
            //切换登录方式
            changeHandle(val) {},

            //登录
            handleLogin() {
                let param = new URLSearchParams();
                if (this.loginType == 0) {
                    if (this.login.account == '') {
                        this.$message.warning('请输入账号');
                        return;
                    }
                    if (this.login.password == '') {
                        this.$message.warning('请输入密码');
                        return;
                    }
                    param.append("username", this.login.account);
                    param.append("password", this.login.password);
                    this.$api.user.login(param).then(res => {
                        console.log(11111111)
                        console.log(res.data);
                        console.log(res.data.data.authorities);
                        let routes_name_pre = res.data.data.authorities.filter((item)=>{return item['role'].indexOf('btn.') == -1}).map(item=>item['role']);
                        let routes_name = routes_name_pre.filter((item)=>{return item.indexOf('ROLE_') == -1});
                        let buttons_name = res.data.data.authorities.filter((item)=>{return item['role'].indexOf('btn.') != -1}).map(item=>item['role']);
                        if (res.data.code == 200) {
                            // this.$message.success("登录成功！");
                            if (this.loginType == 0) {
                                if (this.login.rem) {
                                    this.setCookie('dxaccount', this.login.account, 7);
                                    this.setCookie('dxpassword', this.login.password, 7);
                                }
                            }
                            this.$api.user.getInstitution()
                                .then(res => {
                                    this.institutionName = res.data.data.institutionName
                                    // this.$store.dispatch('user/changeInfo', {
                                    //     name: '',
                                    //     institution: res.data.data.institutionName
                                    // })
                                  this.$store.dispatch('user/changeInfo', {
                                    // 用户信息赋值
                                    name: res.data.data.username,
                                    institution:this.institutionName,
                                    routes: routes_name,
                                    roles:"BN",
                                    buttons: buttons_name,
                                  })
                                  this.$store.dispatch('user/changeResultAsyncRoutes',
                                      cloneDeep(computedAsyncRoutes(asyncRoutes,this.$store.state.user.info.routes))
                                  )
                                  this.$router.addRoutes(this.$store.state.user.resultAsyncRoutes)
                                  // this.$store.dispatch('user/changeInfo', {
                                  //     name: res.data.data.username,
                                  //     institution:''
                                  // })
                                  setToken(1);
                                  this.$router.push({
                                    name: 'batchManage'
                                  });

                                })

                        } else {
                            this.$message.warning("账号或密码错误！")
                        }
                    });

                } else {
                    if (this.loginYzm.mobile == '') {
                        this.$message.warning('请输入手机号');
                        return;
                    }
                    if (this.loginYzm.verCode == '') {
                        this.$message.warning('请输入验证码');
                        return;
                    }
                    param.append('loginType', this.loginType);
                    param.append("mobile", this.loginYzm.mobile);
                    param.append("code", this.loginYzm.verCode);
                    this.$axios.get("user/code_login", {
                        params: param
                    }).then(res => {
                        let code = res.data.code;
                        if (code == -1) {
                            this.$message.warning("手机号错误！");
                            return;
                        } else if (code == 0) {
                            this.$message.warning('验证码错误！');
                            return;
                        } else if (code == 2) {
                            this.$message.warning('验证码已过期，请重新发送。');
                        }
                        let ip = res.data.data.authority;
                        let routes = {
                            "1": 'sendPaperIndex',
                            '2': 'schoolIndex',
                            '3': 'professorIndex',
                            '0': 'adminIndex'
                        };
                        let ids = {
                            "1": 'sendPaperId',
                            '2': 'institutionId',
                            '3': 'expertId',
                            '0': 'adminId'
                        };
                        sessionStorage.setItem(ids[ip], res.data.data.id);
                        sessionStorage.setItem('username', res.data.data.username);
                        sessionStorage.setItem('account', this.login.account);
                        if (ip == 3) sessionStorage.setItem('university', res.data.data.university);
                        this.$store.dispatch('changeIsLogin', 1);
                        this.$router.push({
                            name: routes[ip]
                        });
                    }).catch(res => {
                        this.$message.warning("服务器错误");
                    })
                }
                // this.$axios.get("user/login", {
                //     params: param
                // }).then(res => {
                //     let code = res.data.code;
                //     if (code == '-1') {
                //         this.$message.warning("账号错误！");
                //         return;
                //     } else if (code == '0') {
                //         this.$message.warning('密码错误！');
                //         return;
                //     }
                //     if (this.loginType == 0) {
                //         if (this.login.rem) {
                //             this.setCookie('dxaccount', this.login.account, 7);
                //             this.setCookie('dxpassword', this.login.password, 7);
                //         }
                //     }
                //     let ip = res.data.data.authority;
                //     let routes = {
                //         "1": 'sendPaperIndex',
                //         '2': 'schoolIndex',
                //         '3': 'professorIndex',
                //         '0': 'adminIndex'
                //     };
                //     let ids = {
                //         "1": 'sendPaperId',
                //         '2': 'institutionId',
                //         '3': 'expertId',
                //         '0': 'adminId'
                //     };
                //     sessionStorage.setItem(ids[ip], res.data.data.id);
                //     sessionStorage.setItem('username', res.data.data.username);
                //     sessionStorage.setItem('account', this.login.account);
                //     if (ip == 3) sessionStorage.setItem('university', res.data.data.university);
                //     this.$store.dispatch('changeIsLogin', 1);
                //     this.$router.push({
                //         name: routes[ip]
                //     });
                // }).catch(res => {
                //     this.$message.warning("服务器错误");
                // })
            },
            //获取cookie
            getCookie(key) {
                // console.log(document.cookie.);
                if (document.cookie.length > 0) {
                    let start = document.cookie.indexOf(key + '=');
                    if (start != -1) {
                        start = start + key.length + 1;
                        let end = document.cookie.indexOf(';', start);
                        if (end == -1) end = document.cookie.length;
                        return document.cookie.substring(start, end);
                    }
                }
                return '';
            },
            //设置cookie
            setCookie(name, value, days) {
                var exdate = new Date();
                exdate.setDate(exdate.getDate() + days);
                document.cookie = name + '=' + value + ";expires=" + exdate.toGMTString();
            },
            //获取验证码
            getCode() {
                if (this.loginYzm.mobile == '') {
                    this.$message.warning("请输入手机号！");
                    return;
                }
                if (!this.timer) {
                    let param = new URLSearchParams();
                    param.append('mobile', this.loginYzm.mobile);
                    param.append('type', 0);
                    this.second = 60;
                    this.showBtn = false;
                    this.$axios.get('/sms/send_verification_code', {
                            params: param
                        }).then(res => {
                            if (res.data.data == 0) {
                                this.$message.warning("获取失败!");
                            }
                        })
                        .catch(err => {
                            this.$message.warning("服务器维护!");
                        })
                    this.timer = setInterval(() => {
                        console.log(this.second);
                        if (this.second > 0) {
                            this.second--;
                        } else {
                            this.showBtn = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            }
        },
        components: {

        }
    }
</script>

<style scoped>
    * {
        font-family: "微软雅黑";
        font-size: 16px;
        border: 0;
        padding: 0;
        margin: 0;
        color: #666;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .loginMain {
        width: 100%;
        height: 100vh;
        background: url('../../../public/img/bg2.jpg') no-repeat;
        background-size: 100% 100%;
    }


    .bg1 {
        width: 100%;
        height: 237px;
        background: #347ebc;
        opacity: 0.8;
        position: absolute;
        left: 0;
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
    }

    .gyl {
        width: 510px;
        height: 237px;
        color: #FFFFFF;
        font-size: 60px;
        position: absolute;
        left: 15%;
        top: 11%;
        bottom: 0px;
        margin: auto;
    }

    .gy2 {
        color: #fff;
        margin-left: 6px;
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
    }

    .bg {
        width: 375px;
        height: 337px;
        background: #fff;
        position: relative;
        position: absolute;
        left: 56%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        z-index: 1;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
        border-radius: 5px;
    }

    .password {
        position: absolute;
        top: 9.5rem;
        right: 2.5rem;
        display: flex;
    }

    .yzm {
        position: absolute;
        top: 9.5rem;
        right: 2.5rem;
        display: flex;
    }

    .btn {
        position: absolute;
        top: 16rem;
        right: 2.5rem;
        border: none;
        color: #fff;
        width: 18.825rem;
        text-align: center;
        background-color: #347ebc;
        text-indent: 0rem;
    }

    .img {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
    }

    .wel {
        width: 375px;
        height: 35px;
        color: #347ebc;
        font-size: 1.5rem;
        position: absolute;
        top: 2rem;
        text-align: center;
        /* border-bottom: 1px solid #347ebc; */
    }

    input {
        border-bottom: 0.0625rem solid #347ebc;
        /* border-bottom: 0.0625rem solid red; */
        height: 2.5625rem;
        width: 15.8125rem;
        /* text-indent: 3.125rem; */
        text-indent: 1.5rem;
        outline: none;
    }

    .yzm input {
        border-bottom: 0.0625rem solid #347ebc;
        height: 2.5625rem;
        width: 9rem;
        /* text-indent: 3.125rem; */
        text-indent: 1.5rem;
        outline: none;
    }

    .yzmBtn {
        width: 6.8125rem;
        font-size: 0.8rem;
    }

    .user {
        position: absolute;
        top: 6rem;
        right: 2.5rem;
        display: flex;
    }

    .rem {
        position: absolute;
        top: 12.5rem;
        right: 17.2rem;
        border: none;
        color: #323333;
    }

    .rem input {
        width: 0.8125rem;
    }

    .fg {
        position: absolute;
        top: 12.5rem;
        right: 2.5rem;
        border: none;
        color: #323333;
    }

    #yonghu {
        border-bottom: 1px solid #347ebc;
        height: 41px;
        line-height: 41px;
    }

    #reb {
        margin-top: -33px;
        margin-left: 20px;
        font-size: 11px;
    }

    .bah {
        position: absolute;
        left: 47%;
        bottom: 3%;

    }

    .bah a {
        color: #fff;
    }

    @media (min-width:1201px) and (max-width:1500px) {
        .gyl {
            width: 420px;
            font-size: 60px;
            top: 16%;
        }
    }

    @media (min-width:800px) and (max-width:1200px) {
        .bg {
            width: 355px;
            height: 300px;
        }

        .gyl {
            font-size: 50px;
        }

        .wel {
            width: 355px;
        }

        .user {
            top: 5rem;
            right: 3.5rem;
        }

        .password {
            top: 8rem;
            right: 3.5rem;
        }

        input {
            width: 12rem;
        }

        .yzm {
            top: 8rem;
            right: 3.5rem;
        }

        .yzm input {
            width: 7rem;
        }

        .yzmBtn {
            width: 5rem;
            font-size: 0.3rem;
        }

        .rem {
            top: 11rem;
            right: 14.3rem;
        }

        .fg {
            top: 11rem;
            right: 3.5rem;
        }

        .btn {
            top: 14rem;
            width: 15rem;
        }
    }

    /* 过渡动画 */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1s
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0
    }

    .fade-leave,
    .fade-enter-to {
        opacity: 1
    }
</style>